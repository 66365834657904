import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from '../images/header/logo.png';
import PhoneIcon from '../images/header/phone.png';
import { Link, NavLink } from 'react-router-dom';
import "../styles/header/header.css";

const Navbar = () => {
  return (
    <header className="bg-white py-2 custom-header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-3 col-12 mb-3 mb-lg-0 text-center text-lg-start">
            <Link to="/" className="d-block">
              <img src={Logo} alt="Shree Samarth Fireworks Logo" className="img-fluid" style={{ width: '80%' }} />
            </Link>
          </div>

          <div className="col-lg-6 col-12 order-2 order-lg-1">
            <nav className="navbar navbar-expand-lg navbar-light flex-lg-row flex-column custom-nav">
              <button className="navbar-toggler mb-3 mb-lg-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav w-100 mx-auto d-flex align-items-center justify-content-center">
                  <li className="nav-item px-2">
                    <NavLink to="/" end className={({isActive}) => isActive ? "nav-link fw-bold text-danger" : "nav-link text-dark"}>Home</NavLink>
                  </li>
                  <li className="nav-item px-2">
                    <NavLink to="/about" className={({isActive}) => isActive ? "nav-link fw-bold text-danger" : "nav-link text-dark"}>About us</NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <Link to="/shreesamarth/order-now" className="nav-link text-dark">Products</Link>
                  </li> */}
                  <li className="nav-item px-2">
                    <NavLink to="/product-list" className={({isActive}) => isActive ? "nav-link fw-bold text-danger" : "nav-link text-dark"}>Products</NavLink>
                  </li>
                  <li className="nav-item px-2">
                    <NavLink to="/contact-us" className={({isActive}) => isActive ? "nav-link fw-bold text-danger" : "nav-link text-dark"}>Contact Us</NavLink>
                  </li>


                  <li className="nav-item d-lg-none mt-3">
                    <div className="d-flex justify-content-center align-items-center">

                    <div className="d-flex justify-content-center align-items-center" 
                      style={{
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#ED2B24',
                        borderRadius: '50%',
                        marginRight: '2px'
                      }}>
                      <img src={PhoneIcon} alt="Phone Icon" className="img-fluid" style={{ width: '60%', height: '60%' }} />
                    </div>
                    <span className="fw-semibold text-dark ms-2">8425804489</span>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

          <div className="col-lg-3 d-none d-lg-flex justify-content-end align-items-center order-1 order-lg-2 custom-phone" style={{ cursor: 'pointer'}}>
            <div className="d-flex justify-content-center align-items-center" 
              style={{
                width: '30px',
                height: '30px',
                backgroundColor: '#ED2B24',
                borderRadius: '50%',
              }}>
              <img src={PhoneIcon} alt="Phone Icon" className="img-fluid" style={{ width: '60%', height: '60%' }} />
            </div>
            <span className="fw-semibold text-dark ms-2">+91 8425804489</span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
