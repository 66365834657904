import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import FireworksImg from "../images/home/fireworks.png";
import LoaderImg from "../images/home/loader.png";
import RocketImg from "../images/home/about-rocket.png";
import aboutBg from "../images/home/about-bg.png";
import aboutBg1 from "../images/home/about-bg1.png";


import "../styles/home.css";
import { Link } from "react-router-dom";

const WelcomeAbout = () => {
  const contentRef = useRef(null);
  const [isRotating, setIsRotating] = useState(false);

  const handleLoaderClick = () => {
    setIsRotating(true);

    setTimeout(() => {
      setIsRotating(false);
    }, 1000);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observerInstance) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observerInstance.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current);
      }
    };
  }, []);

  return (
    <div className="welcomeabout-container py-5">
      <h2 className="container about-title mb-5 text-center">
        <img
          src={FireworksImg}
          alt="fireworks"
          className="me-2 fireworks-image"
        />
        About Us
        <img
          src={FireworksImg}
          alt="fireworks"
          className="ms-2 fireworks-image"
        />
      </h2>

      <img
          src={aboutBg1}
          alt="Left Decorative Background"
          className="about-bg1-img"
        />

        <img
          src={aboutBg}
          alt="Right Decorative Background"
          className="about-bg-img"
        />

      <div ref={contentRef} className="container welcome-about-content">
        <div className="row
        ">
        <div className="col-md-5">

        </div>
        <h3 className="col-md-7 welcome-title text-md-start mb-4 ps-4">
          Welcome to Shree Samarth Fireworks!
        </h3>
        </div>

        <div className="row">
          <div className="col-12 col-md-5 c-rocket-image">
            <div className="c-child-img mx-auto">
              <img src={RocketImg} alt="rocket image" />
            </div>
          </div>

          <div className="col-12 col-md-7">
            <p className="about-description mb-4 px-3">
              We Shingre brothers Prithviraj and Sagar started{" "}
              <strong style={{ color: "#ED2B24" }}>
                Shree Samarth Fireworks
              </strong>{" "}
              in 1989. Now our next generation Kshitij and Harshwardhan is ready
              to serve people with quality and dignity. Welcome to Samarth
              Fireworks, your trusted wholesale supplier of premium fireworks
              for over <strong style={{ color: "#ED2B24" }}>35</strong> years.
              Founded on a passion for celebration and a commitment to quality,
              we specialize in providing a wide range of fireworks to retailers
              and event planners across Maharashtra. Our extensive experience in
              the industry allows us to curate a diverse selection of products,
              ensuring that we meet the needs of our customers, whether for
              holidays, special events, or community celebrations. We prioritize
              safety and compliance, sourcing only from reputable manufacturers
              who adhere to the highest standards.
            </p>
            <p className="about-description px-3">
              Our extensive experience in the industry allows us to curate a
              diverse selection of products, ensuring that we meet the needs of
              our customers, whether for holidays, special events, or community
              celebrations. We prioritize safety and compliance, sourcing only
              from reputable manufacturers who adhere to the highest standards.
            </p>
            <div className="text-center mt-4">
              <Link to="/about" className="btn load-more-btn" onClick={handleLoaderClick}>
                <img
                  src={LoaderImg}
                  alt="Loading..."
                  className={`loader-image me-2 ${isRotating ? "rotate" : ""}`}
                />
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeAbout;
