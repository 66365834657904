import React from 'react'
import ProductList from './components/ProductList'
import ProductLists from "./components/products/ProductList";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Navbar from './staticComponent/Navbar'
import Navbar1 from './staticComponent/Navbar1'
import Home from './page/Home'
import About from './page/About'
import Contactus from './page/Contactus'
import Footer from './staticComponent/Footer'
import Product from './page/Product'
import { Toaster } from 'react-hot-toast'
import WhatsAppButton from './staticComponent/WhatsAppButton';
import AdStrip from './staticComponent/AdStrip';

export default function App() {
  return (
    <div className='' >
      <BrowserRouter>
      <AdStrip  />
      <Navbar/>
      {/* <Navbar1/> */}
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/order-now" element={<ProductList/>} />
          <Route path="/contact-us" element={<Contactus/>} />
          <Route path="/product-list" element={<Product/>} />
          <Route path="/product-list/:category" element={<ProductLists />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
      
      <Toaster position="top-center" reverseOrder={false} />

      <WhatsAppButton />

    </div>
  )
}
