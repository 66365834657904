import React from "react";
import designImg from "../images/products/design.png";
import design2Img from "../images/products/design2.png";
import crackersImg from "../images/products/crackers.png"; 

import "../styles/product/product.css"; 
import ProductList from "../components/products/ProductList";

const Product = () => {
  return (
    <div className="custom-product-page">
      <div className="product-section position-relative text-center py-5">
        <img
          src={designImg}
          alt="Cracker Design"
          className="cracker-design cracker-left position-absolute"
        />

        {/* <img
          src={crackersImg}
          alt="Crackers"
          className="cracker position-absolute cracker-left-img"
        />

        <h1 className="products-text">Products</h1>

        <img
          src={crackersImg}
          alt="Crackers"
          className="cracker position-absolute cracker-right-img"
        /> */}
        <div className="container">
          <div className="row">
            <div className="col-4 col-md-4">
              <img src={crackersImg} alt="Crackers" className="cracker" />
            </div>

            <div className="col-4 col-md-4 d-flex justify-content-center align-items-center">
              <h1 className="products-text ">Products</h1>
            </div>

            <div className="col-4 col-md-4">
              <img src={crackersImg} alt="Crackers" className="cracker" />
            </div>
          </div>
        </div>

        <img
          src={design2Img}
          alt="Cracker Design"
          className="cracker-design cracker-right position-absolute"
        />
      </div>

      <div>
          {/* add table here or that component here */}
          <ProductList />
      </div>

    </div>
  );
};

export default Product;
