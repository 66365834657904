import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import '../styles/whatsapp/WhatsAppButton.css'; 

export default function WhatsAppButton() {
  const whatsappNumber = '918425804489'; 
  const message = 'Hello, I am interested in your products!';

  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;

  return (
    <a 
      href={whatsappLink} 
      target="_blank" 
      rel="noopener noreferrer"
      className="whatsapp-button"
    >
      <FaWhatsapp size={40} />
    </a>
  );
}
