import React, { useEffect, useRef } from "react";

import FireworksImg from "../images/home/fireworks.png";
import icon1 from "../images/about_us/icon1.png";
import icon2 from "../images/about_us/icon2.png";
import icon3 from "../images/about_us/icon3.png";
import icon4 from "../images/about_us/icon4.png";

import rocketsImg from "../images/about_us/rocket.png";
import "../styles/product/product.css";
import "../styles/aboutus/about.css";

export default function About() {
  const contentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observerInstance) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observerInstance.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (contentRef.current) {
      observer.observe(contentRef.current);
    }

    return () => {
      if (contentRef.current) {
        observer.unobserve(contentRef.current);
      }
    };
  }, []);

  return (
    <div className="custom-product-page custom-parent-aboutt">
      <div className="position-relative text-center py-5 custom-parent-about-us">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-4"></div>

            <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
              <h1 className="products-text pb-2">About Us</h1>
            </div>

            <div className="col-12 col-md-4">
              <img src={rocketsImg} alt="Crackers" className="cracker" />
            </div>
          </div>
        </div>
      </div>
      <div className="welcomeabout-container about-container">
        <div className="container py-4 py-md-5">
          <h2 className="about-title mb-2 mb-md-3 text-center">
            <img
              src={FireworksImg}
              alt="fireworks"
              className="me-2 fireworks-image"
            />
            About Us
            <img
              src={FireworksImg}
              alt="fireworks"
              className="ms-2 fireworks-image"
            />
          </h2>
        </div>
        <div className="welcome-about-content1">
            <div className="container">
                
          <h3 className="welcome-title text-center mb-4">
            Welcome to Shree Samarth Fireworks!
          </h3>
          <p className="about-description mb-4 px-3">
            At Shree Samarth Fireworks, we believe in lighting up every moment with
            brilliance and excitement. As a leading provider in the fireworks
            industry, our mission is to deliver spectacular experiences that
            leave lasting memories.
          </p>
        </div>
          <div className="container py-4 py-md-5">
            <div className="row justify-content-center">
              <div className="col-md-5">
                <div className="p-4 custom-card-about">
                  <div className="title-wrapper d-flex justify-content-center">
                    <h3 className="custom-title">Our Story</h3>
                  </div>
                  <p className="custom-text-about">
                    At Shree Samarth Fireworks, we believe in lighting up every moment
                    with brilliance and excitement. As a leading provider in the
                    Fireworks industry, our mission is to deliver spectacular
                    experiences that leave lasting memories.
                  </p>
                </div>
              </div>

              <div className="col-md-5">
                <div className="p-4 custom-card-about">
                  <div className="title-wrapper d-flex justify-content-center">
                    <h3 className="custom-title">Our Vision</h3>
                  </div>
                  <p className="custom-text-about">
                    We are committed to enhancing your celebrations with our
                    exceptional range of fireworks. Whether it's a grand public
                    event, a private party, or a small gathering, our team of
                    experts ensures that every display is meticulously planned
                    and executed to perfection.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="whats-setup">
          <div className="container py-5 py-md-5">
            <h2 className="about-title mb-4 mb-md-5 text-center">
              <img
                src={FireworksImg}
                alt="fireworks"
                className="me-2 fireworks-image"
              />
              What Sets Us Apart
              <img
                src={FireworksImg}
                alt="fireworks"
                className="ms-2 fireworks-image"
              />
            </h2>

            <div className="row">
              <div className="col-md-10 mx-auto">
                {[
                  {
                    icon: icon1,
                    title: "Quality & Safety",
                    text: "Our fireworks are sourced from the best manufacturers and adhere to the highest safety standards. We prioritise both the visual impact and the safety of our displays, ensuring peace of mind for all our clients.",
                  },
                  {
                    icon: icon2,
                    title: "Customization",
                    text: "We understand that every event is unique. That’s why we offer customizable firework displays tailored to your specific needs and preferences, creating a personalised experience that reflects your vision.",
                  },
                  {
                    icon: icon3,
                    title: "Expert Team",
                    text: "Our team consists of seasoned professionals with extensive experience in the fireworks industry. From initial consultation to the final display, we provide expert guidance and support every step of the way.",
                  },
                  {
                    icon: icon4,
                    title: "Sustainability",
                    text: "We are dedicated to reducing our environmental footprint. Our fireworks are designed with sustainability in mind, and we are continually exploring ways to make our operations more eco-friendly.",
                  },
                ].map((item, index) => (
                  <div key={index} className="d-flex align-items-start mb-4">
                    <div className="icon-placeholder me-3">
                      <img
                        src={item.icon}
                        alt="icon"
                        className="feature-icon"
                      />
                    </div>
                    <div>
                      <h3 className="feature-title">{item.title}:</h3>
                      <p className="feature-text">{item.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="welcome-about-content1">
        <div className="container py-4 py-md-5">
                
        <div className="">
          <h2 className="about-title mb-4 mb-md-5 text-center">
            <img
              src={FireworksImg}
              alt="fireworks"
              className="me-2 fireworks-image"
            />
            Our Commitment
            <img
              src={FireworksImg}
              alt="fireworks"
              className="ms-2 fireworks-image"
            />
          </h2>
        </div>
                <p className="about-description mb-4 px-3 fw-normal">
                At Shree Samarth, we are passionate about making every event unforgettable. Our commitment to excellence drives us to continually innovate and improve, ensuring that each display is not only a visual masterpiece but also a testament to our dedication to quality and customer satisfaction.
                </p>
              </div>
        </div>
        <div className="join-us-content">
        <div className="container py-4 py-md-5">
                
        <div className="">
          <h2 className="about-title mb-4 mb-md-5 text-center">
            <img
              src={FireworksImg}
              alt="fireworks"
              className="me-2 fireworks-image"
            />
            Join Us in Celebrating Life
            <img
              src={FireworksImg}
              alt="fireworks"
              className="ms-2 fireworks-image"
            />
          </h2>
        </div>
                <p className="about-description mb-4 px-3 fw-normal">
                We invite you to explore our offerings and experience the magic of Firework. Whether you're planning a wedding, corporate event, or community celebration, let us help you make it a dazzling success.

                </p>
              </div>
        </div>
      </div>
    </div>
  );
}
