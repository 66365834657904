import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Banner1Img from "../images/home/home-page-banner1.jpg"; 
import Banner2Img from "../images/home/home-page-banner2.jpg";
import "../styles/home.css";

import { Fireworks } from "@fireworks-js/react";


const Herosection = () => {
  const sectionRef = useRef(null);
  const [showFireworks, setShowFireworks] = useState(false);


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observerInstance) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observerInstance.unobserve(entry.target);
            
            setShowFireworks(true);
            const timer = setTimeout(() => {
              setShowFireworks(false);
            }, 8000); 
        
            return () => clearTimeout(timer);
            
          }
        });
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="manufacturer-section" ref={sectionRef}>
      <div className="container-fluid px-0">
        <Slider {...settings}>
          <div>
            <img src={Banner1Img} className="cust-slider-img" alt="Banner 1" />
          </div>
          <div>
            <img src={Banner2Img} className="cust-slider-img" alt="Banner 2" />
          </div>
        </Slider>
      </div>
      {showFireworks && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            zIndex: 9999,
          }}
        >
          <Fireworks />
        </div>
      )}
    </div>
  );
};

export default Herosection;
