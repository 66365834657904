import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import FireworksImg from '../images/home/fireworks.png';
import FlowerpotImg from '../images/home/flowerpot.jpg';
import ZaminImg from '../images/home/zamin.jpg';
import RocketImg from '../images/home/rocket11.png';
import FancyImg from '../images/home/fancy-fire.png';
import DhamakaImg from '../images/home/dhamaka1.png';
import CrackingImg from '../images/home/gun.png';
import WheelsImg from '../images/home/novelty.png';
import ShortsImg from '../images/home/shorts-cake.png';
import WhizzImg from '../images/home/gift-box.png';
import ThunderImg from '../images/home/paper.png';
import WhipImg from '../images/home/sparkless.png';
import '../styles/home.css';
import { Link, useNavigate } from 'react-router-dom';

const productsData = [
    { img: FlowerpotImg, title: "FLOWER POTS" },
    { img: ZaminImg, title: "GROUND CHAKKARS" },
    { img: DhamakaImg, title: "DHAMAKA" },
    { img: FancyImg, title: "FANCY FIREWORKS" },
    { img: RocketImg, title: "ROCKETS" },
    { img: ShortsImg, title: "SHOTS / CAKES" },
    { img: WhipImg, title: "SPARKLES" },
    { img: WheelsImg, title: "NOVELTY" },
    { img: WhizzImg, title: "GIFT BOX" },
    { img: CrackingImg, title: "GUN" },
    { img: ThunderImg, title: "PAPER/CONFETTI" },
  ];
  
  const Products = () => {
    const [isRotating, setIsRotating] = useState(false);
    const productRefs = useRef([]);
    const navigate = useNavigate();

    useEffect(() => {
      const observer = new IntersectionObserver(
          (entries, observerInstance) => {
              entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                      entry.target.classList.add('animate');
                      observerInstance.unobserve(entry.target);
                  }
              });
          },
          { threshold: 0.1 }
      );

      productRefs.current.forEach((ref) => {
          if (ref) observer.observe(ref);
      });

      return () => {
          productRefs.current.forEach((ref) => {
              if (ref) observer.unobserve(ref);
          });
      };
  }, []);

    const handleLoaderClick = () => {
      setIsRotating(true);

      setTimeout(() => {
        setIsRotating(false); 
    }, 1000);
    };

    const handleClick = () => {
      navigate('/product-list');
    }

    const handleCategoryClick = (category) => {
      // console.log('category clicked', category)
      navigate('/product-list', { state: { category } });
    }



    return (
    <div className='py-2 product-parent'>
      <div className="container my-5 px-3">
        <h2 className="title-product mb-5">
        <img src={FireworksImg} alt="fireworks" className="me-2 fireworks-image" />
        Products
        <img src={FireworksImg} alt="fireworks" className="me-2 fireworks-image" />
        
        </h2>
        <div className="row g-2 product-row">
          {productsData.map((product, index) => (
            <div key={index} className="col-md-3 rounded-3 p-3 product-child" style={{ animationDelay: `${index * 0.2}`, cursor: 'pointer' }} ref={(el) => (productRefs.current[index] = el)} onClick={() => handleCategoryClick(product.title)}>
              <div className="product-box d-flex flex-column justify-content-between">
                <div className="image-wrapper">
                  <img src={product.img} alt={product.title} className="img-fluid product-image" />
                </div>
              </div>
              <h5 className="product-title text-center pt-3">{product.title}</h5>
            </div>
          ))}
          <div className="col-12 text-center mt-4">
            <Link to="/product-list" className="btn load-more-btn" onClick={handleClick}>
            {/* <img src={LoaderImg} alt="Loading..." className={`loader-image me-2 ${isRotating ? 'rotate' : ''}`} /> */}
                Order Now</Link>
          </div>
        </div>
      </div>
      </div>
    );
  
};

export default Products;
