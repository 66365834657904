import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addBill, removeFromBill } from "../../store/slices/billSlice";
import { FaTrash } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import "./product_component.css";

export default function Product({ item, index }) {
  const dispatch = useDispatch();
  const [qty, setQty] = useState(0);
  const [price, setPrice] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const boxPrice = Number(item["1box"]);

    if (qty > 0) {
      setPrice(qty * boxPrice);
      let data = {
        id: item.code,
        name: item.name,
        // brand: item.Brand,
        price: boxPrice,
        quantity: qty,
        total: boxPrice * qty,
      };
      dispatch(addBill(data));
    } else {
      setPrice(0);
      dispatch(removeFromBill(item.code));
    }
  }, [qty]);

  const handleQtyChange = (e) => {
    const value = e.target.value;

    if (!isNaN(value) && value.trim() !== "") {
      setQty(Math.floor(Number(value)));
    } else {
      setQty(0);
    }
  };

  const toggleModal = () => setShowModal(!showModal);

  // console.log("image:", item.image);

  return (
    <>
      <tr
        style={{
          borderBottom: "1px solid #A1A1A1",
          borderTop: "1px solid #A1A1A1",
        }}
        className="custom-tr-product"
      >
        <td
          style={{
            borderRight: "1px solid #A1A1A1",
            padding: "10px",
            textAlign: "center",
          }}
        >
          {item.code}
        </td>
        <td
          style={{
            borderRight: "1px solid #A1A1A1",
            padding: "10px",
            textAlign: "center",
          }}
        >
          {item.image ? (
            <img
              src={`./images/product_images/${item.image}`}
              alt={item.name}
              height="30px"
              className="custom-product-image"
              onError={(e) => (e.target.style.display = "none")}
              onClick={toggleModal}
            />
          ) : null}
        </td>
        <td style={{ borderRight: "1px solid #A1A1A1", padding: "10px" }}>
          {item.name.toUpperCase()}
        </td>
        <td
          style={{
            borderRight: "1px solid #A1A1A1",
            padding: "10px",
            textAlign: "center",
          }}
        >
          ₨. {item["1box"]}
        </td>
        <td
          style={{
            borderRight: "1px solid #A1A1A1",
            padding: "10px",
            textAlign: "center",
          }}
        >
          <input
            type="input"
            className="form-control text-center qty-input"
            value={qty === 0 ? "" : qty}
            onChange={(e) => handleQtyChange(e)}
            placeholder="Qty"
            disabled={!item["1box"]}
          />
        </td>
        <td
          style={{
            borderRight: "1px solid #A1A1A1",
            padding: "10px",
            textAlign: "center",
            fontWeight: "500",
          }}
        >
          ₨. {price}
        </td>
        <td style={{ padding: "10px", textAlign: "center" }}>
          <button
            className="btn btn-danger"
            style={{ backgroundColor: "#ED2B24" }}
            onClick={() => setQty(0)}
          >
            <FaTrash size={18} />
          </button>
        </td>
      </tr>

      <Modal show={showModal} onHide={toggleModal} className="custom-modal" centered>
        <Modal.Header closeButton>
          <Modal.Title style={{color: '#dc3545', textAlign: 'center', margin: '0 auto'}}>{item.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ height: "300px", overflowY: "auto" }}
          className="text-center"
        >
          <img
            src={`./images/product_images/${item.image}`}
            alt={item.name}
            className="img-fluid"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
