import React from "react";
import "../styles/contactus/contactus.css";

export default function Contactus() {
  return (
    <div className="custom-parent-contact-us">
      <div className="custom-contact-us">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-2"></div>
            <div className="col-12 col-md-8">
              <h1>Contact Us</h1>
            </div>
            <div className="col-12 col-md-2"></div>
          </div>
        </div>
      </div>
      <div className="custom-child-contact-us">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <h2 className="contact-us-heading">Contact Us</h2>
              <p className="contact-us-para">
                Please Drop a Mail to Know about More Detail.
              </p>
              <form>
                <div className="row gx-3">
                  <div className="col-md-6 mb-4 position-relative">
                    <input
                      type="text"
                      id="firstName"
                      className="form-control input-field"
                      required
                      placeholder=" "
                    />
                    <label htmlFor="firstName" className="floating-label">
                      First Name<span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-md-6 mb-4 position-relative">
                    <input
                      type="text"
                      id="lastName"
                      className="form-control input-field"
                      required
                      placeholder=" "
                    />
                    <label htmlFor="lastName" className="floating-label">
                      Last Name<span className="text-danger">*</span>
                    </label>
                  </div>
                </div>

                <div className="row gx-3">
                  <div className="col-md-6 mb-4 position-relative">
                    <input
                      type="email"
                      id="email"
                      className="form-control input-field"
                      required
                      placeholder=" "
                    />
                    <label htmlFor="email" className="floating-label">
                      Your Email<span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-md-6 mb-4 position-relative">
                    <input
                      type="tel"
                      id="phone"
                      className="form-control input-field"
                      required
                      placeholder=" "
                    />
                    <label htmlFor="phone" className="floating-label">
                      Phone<span className="text-danger">*</span>
                    </label>
                  </div>
                </div>

                <div className="mb-4 position-relative">
                  <input
                    type="tel"
                    id="altPhone"
                    className="form-control input-field"
                    required
                    placeholder=" "
                  />
                  <label htmlFor="altPhone" className="floating-label">
                    Alternate Phone Number<span className="text-danger">*</span>
                  </label>
                </div>

                <div className="mb-4 custom-textarea">
                  <textarea
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Your Message"
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="btn btn-danger custom-bt-contact mt-3"
                >
                  Send Message
                </button>
              </form>
            </div>

            <div className="col-12 col-md-6 py-5">
              <div className="map-container mb-3">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.453690848627!2d73.10721740969733!3d18.999719654290658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7e86a795eabef%3A0xe737a39ae6cc189!2sShree%20Swami%20Samarth%20Fireworks!5e0!3m2!1sen!2sin!4v1729000962668!5m2!1sen!2sin"
                  width="500"
                  height="280"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  title="Shree Samarth Fireworks Location"
                ></iframe>
              </div>

              <div className="custom-our-address">
                <h4 className="address-heading">Our Address</h4>
                <p>
                  Shree Samarth Fireworks, Shree Swami Samarth Gad,
                  <br />
                  Plot no 14,15,16,17, Sector No 16,
                  <br />
                  Behind Simran Motors, New Panvel West
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
