import React, { useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Logo from "../images/header/logo.png";
import ArrowForward from "../images/footer/arrow-forward.png";
import PhoneImg from "../images/footer/phone.png";
import EmailImg from "../images/footer/email.png";
import LocationImg from "../images/footer/location.png";
import InnovateIcon from "../images/footer/innovate-icon.png"
import Img1 from "../images/footer/img1.png";
import Img2 from "../images/footer/img2.png";  

import "../styles/footer/footer.css";

const Footer = () => {

    const footerRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries, observerInstance) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("animate");
              observerInstance.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.1 } 
      );
  
      if (footerRef.current) {
        observer.observe(footerRef.current);
      }
  
      return () => {
        if (footerRef.current) {
          observer.unobserve(footerRef.current);
        }
      };
    }, []);
  


  return (
    <>
    <footer className="text-white py-5 custom-footer-parent" ref={footerRef}>
    <img src={Img1} alt="Decoration Left" className="footer-img-left" />
        <img src={Img2} alt="Decoration Right" className="footer-img-right" />

      <div className="container">
        <div className="row d-flex align-items-start justify-content-center">
          <div className="col-md-4 text-center text-md-start my-auto pb-4 pb-md-0">
            <img
              src={Logo}
              alt="Shree Samarth Fireworks Logo"
              className="img-fluid footer-logo"
            />
          </div>

          <div className="col-md-2 mb-3 mb-md-0 text-center text-md-start cust-footer-links">
            <h5 className="mb-4 footer-heading text-center text-md-start">
              Link
            </h5>
            <ul className="list-unstyled">
              <li className="pb-3">
                <Link
                  to="/"
                  className="text-decoration-none footer-text-link"
                >
                  <img src={ArrowForward} className="c-arrow-icon me-2" />
                  Home
                </Link>
              </li>
              <li className="pb-3">
                <Link
                  to="/about"
                  className="text-decoration-none footer-text-link"
                >
                  <img src={ArrowForward} className="c-arrow-icon me-2" />
                  About Us
                </Link>
              </li>
              <li className="pb-3">
                <Link
                  to="/product-list"
                  className="text-decoration-none footer-text-link"
                >
                  <img src={ArrowForward} className="c-arrow-icon me-2" />
                  Products
                </Link>
              </li>
              <li className="">
                <Link
                  to="/contact-us"
                  className="text-decoration-none footer-text-link"
                >
                  <img src={ArrowForward} className="c-arrow-icon me-2" />
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-md-4 text-center text-md-start cust-contact-us">
            <h5 className="mb-4 footer-heading text-center text-md-start">
              Contact Us
            </h5>
            <p className="mb-3 footer-text-link text-center text-md-start d-flex align-items-start">
              <img src={LocationImg} className="c-arrow-icon me-1" /> Shree Samarth Fireworks, Shree Swami Samarth Gad, Plot No 14,15,16,17, Sector No
              15 Behind Simran Motors New Panvel West. 410206.
            </p>
            <p className="mb-3 footer-text-link text-center text-md-start d-md-flex align-items-start">
              <img src={PhoneImg} className="c-arrow-icon me-1" /> +91 8425804489  
            </p>
            <p className="footer-text-link text-center text-md-start d-md-flex align-items-start">
              <img src={EmailImg} className="c-arrow-icon me-1" />{" "}
              info@shreesamarthfireworks.com
            </p>
          </div>
        </div>
      </div>
    </footer>

    <div className="bg-light text-dark py-3 text-center copyright-section">
        <div className="container">
          <p className="mb-0">
            <span className="d-block d-md-inline-block me-2">
                &copy;Copyright Shree Samarth Fireworks{" "}
            </span>
            <span className="d-block d-md-inline-block ms-3">
              <img
                src={InnovateIcon}
                alt="icon"
                style={{ width: "17px", height: "17px" }}
              />
                &nbsp;&nbsp;Design Developed by <a href="https://innovativewebs.net/" target="_blank" className="innovative-link">Innovativiewebsolutions</a>
            </span>{" "}
          </p>
        </div>
      </div>

    </>
  );
};

export default Footer;
