import React from "react";
import "../styles/product/product.css"; 

const Notice = ({ 
  animate = true, 
  borderRadius = "50px", 
  display = "inline-block", 
  margin = "50px 0", 
  showBreak = true, 
  sticky = false,
  mobile = false,
}) => {
  const noticeStyles = {
    animation: animate ? "bounce 2s infinite" : "none",
    backgroundColor: "#ED2B24",
    color: "#F9F800",
    borderRadius: mobile ? "16px" :borderRadius,
    padding: "10px 20px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "28px",
    display: display,
    textAlign: "center",
    margin: margin,
    position: sticky ? "sticky" : "static",
    top: sticky ? "50px" : "auto", 
    zIndex: sticky ? "1000" : "auto",
    boxShadow: sticky ? "0 2px 10px rgba(0, 0, 0, 0.1)" : "none",
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div className="notice-container" style={noticeStyles}>
        Note: *Wholesale rate applies to orders of <b>5 or more Units.</b> 
        {showBreak && <br />}
        (होलसेल दर ५ किंवा अधिक बॉक्सच्या ऑर्डरसाठी लागू आहे.)
      </div>
    </div>
  );
};

export default Notice;
