import React from 'react';

const AdStrip = () => {
  return (
    <div
      className="text-white text-center overflow-hidden position-relative"
      style={{ height: "40px", backgroundColor: "#ed2b24" }}
    >
      <style>{`
        .marquee-wrapper {
          display: flex;
          width: 100%;
          overflow: hidden;
          position: relative;
        }

        .marquee {
          display: inline-block;
          white-space: nowrap;
          animation: marquee 30s linear infinite;
          padding-top: 10px;
        }

        .marquee:hover {
          animation-play-state: paused;
        }

        @keyframes marquee {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }

        @media (max-width: 768px) {
          .marquee {
            animation: marquee 20s linear infinite;
          }
        }

      `}</style>

      <div className="marquee-wrapper">
        <div className="marquee">
          Note: *Wholesale rate applies to orders of <b>5 or more Units.</b> (होलसेल दर ५ किंवा अधिक बॉक्सच्या ऑर्डरसाठी लागू आहे.)
        </div>
      </div>
    </div>
  );
};

export default AdStrip;
