import React, {useRef, useState} from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";  
import "slick-carousel/slick/slick-theme.css";
import { Modal } from "react-bootstrap";

import video1 from "../media/videos/video/video1.mp4";
import video2 from "../media/videos/video/video2.mp4";
import video3 from "../media/videos/video/video3.mp4";
import video4 from "../media/videos/video/video4.mp4";
import video5 from "../media/videos/video/video5.mp4";
import video6 from "../media/videos/video/video6.mp4";

import thumbnail1 from "../media/videos/thumbnail/thumbnail1.png";
import thumbnail2 from "../media/videos/thumbnail/thumbnail2.png";
import thumbnail3 from "../media/videos/thumbnail/thumbnail3.png";
import thumbnail4 from "../media/videos/thumbnail/thumbnail4.png";
import thumbnail5 from "../media/videos/thumbnail/thumbnail5.png";
import thumbnail6 from "../media/videos/thumbnail/thumbnail6.png";

import FireworksImg from "../images/home/fireworks.png";
import forwardArrow from "../images/home/forwardArrow.png";
import backArrow from "../images/home/backArrow.png";



const videos = [
  { thumbnail: thumbnail1, src: video1 },
  { thumbnail: thumbnail2, src: video2 },
  { thumbnail: thumbnail3, src: video3 },
  { thumbnail: thumbnail4, src: video4 },
  { thumbnail: thumbnail5, src: video5 },
  { thumbnail: thumbnail6, src: video6 },
];

const VideoSection = () => {
  const videoRefs = useRef([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);

  const openModal = (index) => {
    setActiveVideo(index);
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
    if(videoRefs.current[activeVideo]) {
      videoRefs.current[activeVideo].pause();
    }
  }


  const handlePlayPause = (index) => {
    const currentVideo = videoRefs.current[index];

    videoRefs.current.forEach((video, i) => {
      if (i !== index && video) {
        video.pause();
      }
    });

    if (currentVideo) {
      if (currentVideo.paused) {
        currentVideo.play();
      } else {
        currentVideo.pause();
      }
    }
  };

  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    infinite: true,
    responsive: [
        {
          breakpoint: 1024, 
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
          },
        },
        {
          breakpoint: 768, 
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
          },
        },
        {
          breakpoint: 480, 
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    
  };

  return (
    <div className="custom-video-section py-5">
      <h2 className="container about-title mb-5 text-center c-text-video">
        <img
          src={FireworksImg}
          alt="fireworks"
          className="me-2 fireworks-image"
        />
        Our Video Gallery
        <img
          src={FireworksImg}
          alt="fireworks"
          className="ms-2 fireworks-image"
        />
      </h2>

      <div className="container">
        <Slider {...settings}>
          {videos.map((video, index) => (
            <div key={index} className="video-slide">
              <div
                // onClick={() => handlePlayPause(index)}
                onClick={() => openModal(index)}
                style={{
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <img
                  src={video.thumbnail}
                  alt={`Thumbnail ${index + 1}`}
                  width="100%"
                  height="100%"
                />
                {/*<video
                  ref={(el) => (videoRefs.current[index] = el)}
                  width="100%"
                  poster={video.thumbnail}
                  controls={false}
                  className="video-player"
                  playsInline 
                >
                  <source src={video.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>*/}
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <Modal
        show={isModalOpen}
        onHide={closeModal}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Body className="p-0 video-modal-body">
          <video
            ref={(el) => (videoRefs.current[activeVideo] = el)}
            width="100%"
            controls
            autoPlay
            className="video-player"
          >
            <source src={videos[activeVideo]?.src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow`}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#424853",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          cursor: "pointer",
          zIndex: 10,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
        onClick={onClick}
      >
        <img
          src={forwardArrow}
          alt="Next"
          style={{ width: "60%", height: "60%" }}
        />
      </div>
    );
  };
  
  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-arrow`}
        style={{
          ...style,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#de5351",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          cursor: "pointer",
          zIndex: 10,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
        onClick={onClick}
      >
        <img
          src={backArrow}
          alt="Previous"
          style={{ width: "50%", height: "50%" }}
        />
      </div>
    );
  };
    
export default VideoSection;
