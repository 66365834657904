import SPARKLERS from "./sparklers"

const products = [
  {
    categoryName: "Sparklers", 
    items: SPARKLERS,
  },
];


export default products