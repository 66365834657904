import React, { useEffect, useRef, useState } from "react";
import products from "../../data/productData";
import Product from "./Product";
import { useSelector } from "react-redux";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import "./product_component.css";
import toast from "react-hot-toast";
import { Accordion, Button, Card } from "react-bootstrap";

import logo from "../../images/header/logo.png";
import { useLocation, useParams } from "react-router-dom";
import Notice from "../../staticComponent/Notice";

import { Fireworks } from "@fireworks-js/react";

const groupByCategory = (items) => {
  return items.reduce((acc, item) => {
    const category = item.category || "Miscellaneous";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});
};

export default function ProductList() {
  const billData = useSelector((state) => state.bill);
  const location = useLocation();
  const { category } = location.state || {};
  // const {category} = useParams();
  const accordionRef = useRef(null);
  const [activeKey, setActiveKey] = useState(null); 
  const headerRefs = useRef({});
  const [categoryName, setCategoryName] = useState('');
  const [showFireworks, setShowFireworks] = useState(false); 

  // console.log('category:', category);

  const groupedProducts = groupByCategory(products);

  const totalProducts = billData.filter((item) => item.quantity > 0).length;
  const totalItems = billData.reduce((sum, item) => sum + item.quantity, 0);
  const totalAmount = billData.reduce((sum, item) => sum + item.total, 0);


  const scrollToHeader = (headerElement) => {
    const stickyHeaderHeight = 170;
    const topPosition = headerElement.getBoundingClientRect().top + window.scrollY;
    
    window.scrollTo({
      top: topPosition - stickyHeaderHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const decodedCategory = decodeURIComponent(category || categoryName).trim();
    const categoryIndex = Object.keys(groupedProducts).findIndex(
      (key) => key.toLowerCase() === decodedCategory?.toLowerCase()
    );

    if (categoryIndex !== -1) {
      setActiveKey(categoryIndex.toString());

      setTimeout(() => {
        const headerElement = headerRefs.current[categoryIndex];
        if (headerElement) scrollToHeader(headerElement);
      }, 100);
    }

    setShowFireworks(true);
    const timer = setTimeout(() => {
      setShowFireworks(false);
    }, 8000); 

    return () => clearTimeout(timer);

  }, []);


  const handleAccordionSelect = (key) => {
    setActiveKey(key);
    const selectedCategory = Object.keys(groupedProducts)[key];
    setCategoryName(selectedCategory);
    // console.log("Selected Category:", selectedCategory);
  };





  // const generatePDF = () => {
  //   if (
  //     billData.length === 0 ||
  //     billData.every((item) => item.quantity === 0)
  //   ) {
  //     toast.error("No items to bill. Please select at least one item.");
  //     return;
  //   }

  //   const doc = new jsPDF();
  //   const tableColumn = ["id", "Item", "Quantity", "Price", "Total"];
  //   const tableRows = [];
  //   let totalPrice = 0;

  //   billData.forEach((item) => {
  //     const itemData = [
  //       item.id,
  //       item.name,
  //       item.quantity,
  //       `Rs. ${item["1box"]}`,
  //       `Rs. ${item.total}`,
  //     ];
  //     tableRows.push(itemData);
  //     totalPrice += item.total;
  //   });

  //   doc.autoTable({
  //     startY: 10,
  //     head: [tableColumn],
  //     body: tableRows,
  //   });

  //   const finalY = doc.lastAutoTable.finalY || 80;
  //   doc.setFontSize(12);
  //   doc.text(`Total: Rs. ${totalPrice}`, 10, finalY + 10);
  //   doc.save("bill.pdf");
  // };


  // const generatePDF = () => {
  //   if (
  //     billData.length === 0 ||
  //     billData.every((item) => item.quantity === 0)
  //   ) {
  //     toast.error("No items to bill. Please select at least one item.");
  //     return;
  //   }

  //   const doc = new jsPDF();
  //   // const reader = new FileReader();

  //   // reader.readAsDataURL(logo);

  //   const img = new Image();
  //   img.src = logo;

  //   img.onload = () => {
  //     // const logoBase64 = reader.result;

  //     const canvas = document.createElement("canvas");
  //     canvas.width = img.width;
  //     canvas.height = img.height;

  //     const ctx = canvas.getContext("2d");
  //     ctx.drawImage(img, 0, 0);

  //     const logoBase64 = canvas.toDataURL("image/png");

  //     doc.addImage(logoBase64, "PNG", 85, 5, 40, 15);

  //     doc.setFontSize(12);
  //     doc.text(`Date: ${new Date().toLocaleDateString()}`, 10, 30);

  //     const tableColumn = [
  //       "Sr.No",
  //       "Code",
  //       "Product Name",
  //       "Content",
  //       "Qty",
  //       "Rate",
  //       "Amount",
  //     ];
  //     const tableRows = [];
  //     let subTotal = 0;

  //     billData.forEach((item, index) => {
  //       const itemData = [
  //         index + 1,
  //         item.id,
  //         item.name,
  //         item.content || "PKT",
  //         item.quantity,
  //         item.price.toFixed(2),
  //         (item.price * item.quantity).toFixed(2),
  //       ];
  //       tableRows.push(itemData);
  //       subTotal += item.price * item.quantity;
  //     });

  //     doc.setFillColor(237, 43, 36);

  //     doc.rect(10, 35, 190, 10, "F");

  //     doc.autoTable({
  //       startY: 35,
  //       head: [tableColumn],
  //       body: tableRows,
  //       theme: "grid",
  //       styles: { halign: "center" },
  //       headStyles: { 
  //         fillColor: [237, 43, 36],
  //         textColor: [255, 255, 255], 
  //       },
  //     });

  //     const finalY = doc.lastAutoTable.finalY || 80;
  //     const discount = (subTotal * 0.8).toFixed(2); 
  //     const total = (subTotal - discount).toFixed(2);

  //     doc.setFontSize(12);
  //     doc.text(`Sub Total: Rs. ${subTotal.toFixed(2)}`, 140, finalY + 10);
  //     doc.text(`Discount (80%): Rs. ${discount}`, 140, finalY + 20);
  //     doc.text(`Total: Rs. ${total}`, 140, finalY + 30);

  //     doc.text(`Net Total: Rs. ${total}`, 140, finalY + 50);
  //     doc.text(`Overall Total: Rs. ${total}`, 140, finalY + 60);
  //     doc.text(`Total Items: ${billData.length}`, 10, finalY + 60);

  //     doc.save("bill.pdf");
  //   };

  //   img.onerror = () => {
  //     toast.error("Failed to load logo image");
  //   };
  // };

  
  //updating code

  const generatePDF = () => {
    if (
      billData.length === 0 ||
      billData.every((item) => item.quantity === 0)
    ) {
      toast.error("No items to bill. Please select at least one item.");
      return;
    }
  
    const doc = new jsPDF();
  
    const img = new Image();
    img.src = logo;
  
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
  
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
  
      const logoBase64 = canvas.toDataURL("image/png");
      doc.addImage(logoBase64, "PNG", 75, 5, 60, 20);
  
      doc.setFontSize(12);
  
      const formatDate = (date) => {
        const d = date.getDate().toString().padStart(2, "0");
        const m = (date.getMonth() + 1).toString().padStart(2, "0");
        const y = date.getFullYear();
        return `${d}/${m}/${y}`;
      };
  
      let currentY = 30;
      const leftMargin = 15;
  
      doc.text(`Date: ${formatDate(new Date())}`, leftMargin, currentY);
      currentY += 10;
  
      const tableColumn = ["Code", "Product Name", "Qty", "Rate", "Amount"];
      const tableRows = [];
      let total = 0;
  
      billData.forEach((item) => {
        const itemData = [
          item.id,
          item.name,
          item.quantity,
          item.price.toFixed(2),
          (item.price * item.quantity).toFixed(2),
        ];
        tableRows.push(itemData);
        total += item.price * item.quantity;
      });
  
      const totalRow = [
        {
          content: `Total Items: ${billData.length}`,
          colSpan: 3,
          styles: { halign: "left", fontStyle: "bold", textColor: [0, 0, 0] },
        },
        { content: "Total", styles: { halign: "center", fontStyle: "bold" } },
        { content: total.toFixed(2), styles: { halign: "center" } },
      ];
      tableRows.push(totalRow);
  
      doc.autoTable({
        startY: currentY,
        margin: { left: leftMargin },
        head: [tableColumn],
        body: tableRows,
        theme: "grid",
        styles: { halign: "center", lineColor: [237, 43, 36], lineWidth: 0.001 },
        headStyles: {
          fillColor: [237, 43, 36],
          textColor: [255, 255, 255],
        },
        tableLineColor: [237, 43, 36],
        tableLineWidth: 0.001,
        didDrawCell: (data) => {
          if (data.row.index === tableRows.length - 1) {
            doc.setFont("helvetica", "bold");
            doc.setTextColor(0, 0, 255);
          }
          if (data.row.index === tableRows.length - 1 && data.column.index < 3) {
            doc.setDrawColor(255, 255, 255);
          }
        },
      });
  
      currentY = doc.lastAutoTable.finalY + 15;
  
      doc.setFont("helvetica", "bold");
      doc.text("Contact Us", leftMargin, currentY);
      currentY += 8;
  
      doc.text("Address:", leftMargin, currentY);
      currentY += 7;
  
      const address = [
        "Shree Samarth Fireworks,",
        "Shree Swami Samarth Gad,",
        "Plot No 14,15,16,17,",
        "Sector No 15,",
        "Behind Simran Motors,",
        "New Panvel West. 410206.",
      ];
  
      address.forEach((line) => {
        doc.setFont("helvetica", "normal");
        doc.text(line, leftMargin, currentY);
        currentY += 7;
      });
  
      currentY += 5;
      doc.setFont("helvetica", "bold");
      doc.setTextColor(0, 0, 0); 
      doc.text("Phone No:", leftMargin, currentY);
      currentY += 7;
      doc.setFont("helvetica", "normal");
      doc.text("+91 8425804489", leftMargin, currentY);
  
      doc.save("bill.pdf");
    };
  
    img.onerror = () => {
      toast.error("Failed to load logo image");
    };
  };
  

  const headerStyle = {
    fontFamily: "Poppins",
    fontSize: "17px",
    fontWeight: "500",
    lineHeight: "30px",
    textAlign: "center",
    backgroundColor: "#ED2B24",
    color: "#FFFFFF",
    borderRight: "1px solid #FFFFFF",
  };

  return (
    <div className="container py-3 py-md-5 custom-table-parent">
      {showFireworks && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            zIndex: 9999,
          }}
        >
          <Fireworks />
        </div>
      )}

      <div className="custom-position fixed-header">
        <div
          className="row mx-auto mb-4 c-total-header"
          style={{ border: "0.1px solid #A1A1A1", background: "#F9F9F9" }}
        >
          <div
            className="col-4 col-md-4 text-center"
            style={{ borderRight: "0.1px solid #A1A1A1" }}
          >
            <div className="pt-1 p-md-3">
              <span className="">No of Products</span>
              <h5>{totalProducts}</h5>
            </div>
          </div>
          <div
            className="col-4 col-md-4 text-center"
            style={{ borderRight: "0.1px solid #A1A1A1" }}
          >
            <div className="pt-1 p-md-3">
              <span className="">No of Items</span>
              <h5>{totalItems}</h5>
            </div>
          </div>
          <div className="col-4 col-md-4 text-center">
            <div className="pt-1 p-md-3">
              <span className="">Total Amount</span>
              <h5>₨. {totalAmount}</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="fixed-header1">
        <Notice
          animate={false}
          borderRadius="6px"
          display="block"
          margin="20px 0"
          showBreak={false}
          sticky={true}
          mobile={true}
        />
      </div>

      <Accordion
        ref={accordionRef}
        activeKey={activeKey}
        onSelect={handleAccordionSelect}
      >
        {Object.entries(groupedProducts).map(([category, items], index) => (
          <Accordion.Item eventKey={index.toString()} key={index}>
            <Accordion.Header
              style={{
                color: "#ED2B24",
                fontFamily: "Poppins",
                fontSize: "25px",
                fontWeight: "500",
                lineHeight: "30px",
                textAlign: "center",
                marginBottom: "unset",
              }}
              className="custom-accordion-header"
              ref={(el) => (headerRefs.current[index] = el)}
            >
              {category}
            </Accordion.Header>
            <Accordion.Body>
              <div className="table-responsive">
                <table
                  className="table table-striped"
                  style={{
                    borderLeft: "0.1px solid #ED2B24",
                    borderRight: "0.1px solid #ED2B24",
                  }}
                >
                  <thead className="table-header">
                    <tr>
                      <th style={headerStyle} className="col-1">
                        Code
                      </th>
                      <th style={headerStyle} className="col-1">
                        Image
                      </th>
                      <th style={headerStyle} className="col-4">
                        Product Name
                      </th>
                      <th style={headerStyle} className="col-2">
                        Price
                      </th>
                      <th style={headerStyle} className="col-3 col-md-2">
                        Qty
                      </th>
                      <th style={headerStyle} className="col-2">
                        Total
                      </th>
                      <th style={headerStyle} className="col-1">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, idx) => (
                      <Product item={item} key={idx} />
                    ))}
                  </tbody>
                </table>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

        <div style={{display: 'none'}} >
          <Notice  />
        </div>

      <button
        className="position-fixed btn btn-warning end-0 m-4 shadow-lg"
        onClick={generatePDF}
        style={{ bottom: "70px", right: "0px", zIndex: "99999" }}
      >
        Download Bill
      </button>
    </div>
  );
}
